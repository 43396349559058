import { format, isAfter, isBefore, isEqual, isWithinInterval, parseISO } from "date-fns"

export function formatDateTime(date: string): string {
  return format(new Date(date), "yyyy/MM/dd hh:mm")
}

export function formatDate(date: string): string {
  return format(new Date(date), "yyyy/MM/dd")
}

export function formatDateObject(date: Date): string {
  return format(date, "yyyy/MM/dd")
}

export function isBeforeOrEqualISO(date: string, dateToCompare: string): boolean {
  return isBeforeISO(date, dateToCompare) || isEqual(new Date(date), new Date(dateToCompare))
}

export function isBeforeOrEqual(date: Date, toCompare: Date): boolean {
  const formatted = formatDateISO(date)
  const formattedToCompare = formatDateISO(toCompare)

  return isBeforeISO(formatted, formattedToCompare) || isEqualISO(formatted, formattedToCompare)
}

export function formatDateISO(date: Date): string {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().substring(0, 10)
}

export function isAfterISO(date: string, toCompare: string): boolean {
  return isAfter(parseISO(date), parseISO(toCompare))
}

export function isBeforeISO(date: string, toCompare: string): boolean {
  return isBefore(parseISO(date), parseISO(toCompare))
}

export function isEqualISO(date: string, toCompare: string): boolean {
  return isEqual(parseISO(date), parseISO(toCompare))
}

export function isWithinIntervalISO(date: string, start: string, end: string): boolean {
  return isWithinInterval(parseISO(date), { start: parseISO(start), end: parseISO(end) })
}
